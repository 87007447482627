import { useState } from "react";
import styles from "./SideBar.module.css";
import SideBarItem from "./SideBarItem";
import SideBarSubItem from "./SideBarSubItem";

function SideBar() {
  const [isVisible, setIsVisible] = useState(false);

  const toggleSidebar = () => {
    setIsVisible(!isVisible);
  };
  return (
    <>
      <div className={styles.spanButton} onClick={toggleSidebar}>
        <button className={styles.ToggleCopyButton}>{isVisible ? "📕" : "📂"}</button>
        <div className={`${styles.SideBar} ${isVisible ? styles.visible : styles.invisible}`}>
          <SideBarItem title="🔢 Encoder" url="/base64-encode-decode">
            <SideBarSubItem title="Base64 Encoder / Decoder" url="/base64-encode-decode"></SideBarSubItem>
            <SideBarSubItem title="Hexadecimal Encoder / Decoder" url="/ascii-to-hexa"></SideBarSubItem>
            <SideBarSubItem title="Binary Encoder / Decoder" url="/unicode-to-binary"></SideBarSubItem>
            <SideBarSubItem title="Base Converter" url="/base-converter"></SideBarSubItem>
          </SideBarItem>
          {/* <SideBarSubItem
        title="Base64 to Image"
        url="/base64-encode-decode"
      ></SideBarSubItem>
      <SideBarSubItem
        title="Base64 to Audio"
        url="/base64-encode-decode"
      ></SideBarSubItem> */}
          <SideBarItem title="🌐 URL" url="/url-encode-decode">
            <SideBarSubItem title="URL Encoder / Decoder" url="/url-encode-decode"></SideBarSubItem>
            {/* <SideBarSubItem
              title="URL Parser / Query splitter"
              url="/url-parser-splitter"
            ></SideBarSubItem> */}
            {/* <SideBarSubItem
          title="URL Comparision"
          url="/url-comparision"
        ></SideBarSubItem>
        <SideBarSubItem
          title="URL Parser"
          url="/url-comparision"
        ></SideBarSubItem> */}
          </SideBarItem>
          <SideBarItem title="💻 Web tools" url="/url-encode-decode">
            <SideBarSubItem title="SVG to CSS url encoder" url="/svg-to-css"></SideBarSubItem>
          </SideBarItem>
          <SideBarItem title="💅 Text formatter" url="/sort-deduplicate-lines">
            {/* <SideBarSubItem
          title="Text analysis (character, line count)"
          url="/url-comparision"
        ></SideBarSubItem> */}
            <SideBarSubItem title="Sort / Deduplicate / Reverse lines" url="/sort-deduplicate-lines"></SideBarSubItem>
            <SideBarSubItem title="String case converter" url="/casing"></SideBarSubItem>
            <SideBarSubItem title="Add delimiters" url="/delimiters"></SideBarSubItem>
            <SideBarSubItem title="Trim text" url="/trim-text"></SideBarSubItem>
            <SideBarSubItem title="Backslash escape" url="/backslash-escape"></SideBarSubItem>
            <SideBarItem title="🛠 Extractors" url="/html-stripper">
              <SideBarSubItem title="HTML stripper" url="/html-stripper"></SideBarSubItem>
              <SideBarSubItem title="Email extractor" url="/email-extractor"></SideBarSubItem>
              <SideBarSubItem title="URL extractor" url="/url-extractor"></SideBarSubItem>
              <SideBarSubItem title="Phone number extractor" url="/phone-number-extractor"></SideBarSubItem>
            </SideBarItem>
            <SideBarItem title="💅 Formatters" url="/html-beautify-minify">
              <SideBarSubItem title="JS beautify" url="/js-beautify-minify"></SideBarSubItem>
              <SideBarSubItem title="HTML beautify" url="/html-beautify-minify"></SideBarSubItem>
              <SideBarSubItem title="CSS beautify" url="/css-beautify-minify"></SideBarSubItem>
              <SideBarSubItem title="JSON beautify" url="/json-beautify-minify"></SideBarSubItem>
              <SideBarSubItem title="XML beautify" url="/xml-beautify-minify"></SideBarSubItem>
              <SideBarSubItem title="YAML to JSON" url="/yaml-to-json"></SideBarSubItem>
              <SideBarSubItem title="JSON to YAML" url="/json-to-yaml"></SideBarSubItem>
              <SideBarSubItem title="JSON to CSV" url="/json-to-csv"></SideBarSubItem>
              <SideBarSubItem title="CSV to JSON" url="/csv-to-json"></SideBarSubItem>
            </SideBarItem>
          </SideBarItem>
          <SideBarItem title="✔️ Validator" url="/json-validator">
            <SideBarSubItem title="JSON validator" url="/json-validator"></SideBarSubItem>
            <SideBarSubItem title="YAML validator" url="/yaml-validator"></SideBarSubItem>
            <SideBarSubItem title="XML validator" url="/xml-validator"></SideBarSubItem>
          </SideBarItem>
          {/* <SideBarItem title="SEO tools" url="/seo-tools">
        <SideBarSubItem title="Domain age" url="/domain-age"></SideBarSubItem>
      </SideBarItem> */}
        </div>
      </div>
    </>
  );
}

export default SideBar;
