import { NavLink } from "react-router-dom";
import styles from "./SideBarSubItem.module.css";

function SideBarSubItem(props) {
  return (
    <NavLink className={`${styles.NavLink} ${styles.title}`} to={props.url}>
      <div className={styles.SideBarItemWrapper}>{props && props.title}</div>
    </NavLink>
  );
}

export default SideBarSubItem;
