import { getBackslashEscaped } from "@jordibosch20/software_tools_package/dist/utils/backslash.js";
import { getBase64String } from "@jordibosch20/software_tools_package/dist/utils/base64.js";
import { getBinaryString } from "@jordibosch20/software_tools_package/dist/utils/binary.js";
import { getStringCased } from "@jordibosch20/software_tools_package/dist/utils/casing.js";
import { geCSSBeautifyString } from "@jordibosch20/software_tools_package/dist/utils/css-beautify";
import { getCSVToJSON } from "@jordibosch20/software_tools_package/dist/utils/csv2json.js";
import { getDelimiters } from "@jordibosch20/software_tools_package/dist/utils/delimiters.js";
import { getEmail } from "@jordibosch20/software_tools_package/dist/utils/email-extractor";
import { getHexaString } from "@jordibosch20/software_tools_package/dist/utils/hexa.js";
import { getHTMLBeautifyString } from "@jordibosch20/software_tools_package/dist/utils/html-beautify";
import { getTextFromHTML } from "@jordibosch20/software_tools_package/dist/utils/html-stripper";
import { getJSBeautifyString } from "@jordibosch20/software_tools_package/dist/utils/js-beautify";
import { getJSONValidity } from "@jordibosch20/software_tools_package/dist/utils/json-validate.js";
import { getJSONToCSV } from "@jordibosch20/software_tools_package/dist/utils/json2csv.js";
import { getJSONToYAML } from "@jordibosch20/software_tools_package/dist/utils/json2yaml";
import { getPhoneNumber } from "@jordibosch20/software_tools_package/dist/utils/phone-number-extractor.js";
import { getSortedLines } from "@jordibosch20/software_tools_package/dist/utils/sort.js";
import { getSVG2CSS } from "@jordibosch20/software_tools_package/dist/utils/svg2css.js";
import { getStringTrimmed } from "@jordibosch20/software_tools_package/dist/utils/trim.js";
import { getURLs } from "@jordibosch20/software_tools_package/dist/utils/url-extractor.js";
import { getURLEncoding } from "@jordibosch20/software_tools_package/dist/utils/url.js";
import { getXMLBeautifyString } from "@jordibosch20/software_tools_package/dist/utils/xml-beautify.js";
import { getXMLValidity } from "@jordibosch20/software_tools_package/dist/utils/xml-validate.js";
import { getYAMLValidity } from "@jordibosch20/software_tools_package/dist/utils/yaml-validate.js";
import { getYAMLToJSON } from "@jordibosch20/software_tools_package/dist/utils/yaml2json";
import { lazy, Suspense } from "react";
import { useLocation } from "react-router-dom";

const Base64CentralWrapper = lazy(
  () => import("@jordibosch20/software_tools_package/dist/pages/Base64CentralWrapper.component"),
);
const BaseConverter = lazy(() => import("@jordibosch20/software_tools_package/dist/pages/BaseConverter.component.js"));
const BinaryCentralWrapper = lazy(
  () => import("@jordibosch20/software_tools_package/dist/pages/BinaryCentralWrapper.component.js"),
);
const CasingCentralWrapper = lazy(
  () => import("@jordibosch20/software_tools_package/dist/pages/CasingCentralWrapper.component.js"),
);
const CSSBeautifyComponent = lazy(
  () => import("@jordibosch20/software_tools_package/dist/pages/CSSBeautify.component.js"),
);
const CSV2JSON = lazy(() => import("@jordibosch20/software_tools_package/dist/pages/CSV2JSON.js"));
const DelimitersCentralWrapper = lazy(
  () => import("@jordibosch20/software_tools_package/dist/pages/DelimitersCentralWrapper.component.js"),
);
const Extractor = lazy(() => import("@jordibosch20/software_tools_package/dist/pages/Extractor.component.js"));
const HexaCentralWrapper = lazy(
  () => import("@jordibosch20/software_tools_package/dist/pages/HexCentralWrapper.component.js"),
);
const HTMLBeautifyComponent = lazy(
  () => import("@jordibosch20/software_tools_package/dist/pages/HTMLBeautify.component.js"),
);
const JSBeautifyComponent = lazy(
  () => import("@jordibosch20/software_tools_package/dist/pages/JSBeautify.component.js"),
);
const JSON2CSV = lazy(() => import("@jordibosch20/software_tools_package/dist/pages/JSON2CSV.js"));
const JSONTOYAMLComponent = lazy(() => import("@jordibosch20/software_tools_package/dist/pages/JSON2YAML.js"));
const NotFoundComponent = lazy(() => import("@jordibosch20/software_tools_package/dist/pages/NotFound.js"));
const SortCentralWrapper = lazy(
  () => import("@jordibosch20/software_tools_package/dist/pages/SortCentralWrapper.component.js"),
);
const SVG2CSS = lazy(() => import("@jordibosch20/software_tools_package/dist/pages/SVG2CSS.js"));
const TrimCentralWrapper = lazy(
  () => import("@jordibosch20/software_tools_package/dist/pages/TrimCentralWrapper.component.js"),
);
const URLCentralWrapper = lazy(
  () => import("@jordibosch20/software_tools_package/dist/pages/URLCentralWrapper.component.js"),
);
const ValidatorComponent = lazy(() => import("@jordibosch20/software_tools_package/dist/pages/Validator.component.js"));
const XMLBeautifyComponent = lazy(
  () => import("@jordibosch20/software_tools_package/dist/pages/XMLBeautify.component"),
);
const YAML2JSONComponent = lazy(() => import("@jordibosch20/software_tools_package/dist/pages/YAML2JSON.js"));

function RouterWrapper() {
  let url = useLocation();
  switch (url.pathname) {
    case "/":
      return (
        <Suspense fallback={<div>Loading...</div>}>
          <Base64CentralWrapper title={"Base64 Encoder / Decoder"} f={getBase64String}></Base64CentralWrapper>
        </Suspense>
      );
    case "/base64-encode-decode":
      return (
        <Suspense fallback={<div>Loading...</div>}>
          <Base64CentralWrapper title={"Base64 Encoder / Decoder"} f={getBase64String}></Base64CentralWrapper>
        </Suspense>
      );
    case "/ascii-to-hexa":
      return (
        <Suspense fallback={<div>Loading...</div>}>
          <HexaCentralWrapper title={"Hexadecimal Encoder / Decoder"} f={getHexaString}></HexaCentralWrapper>
        </Suspense>
      );
    case "/unicode-to-binary":
      return (
        <Suspense>
          <BinaryCentralWrapper title={"Binary Encoder / Decoder"} f={getBinaryString}></BinaryCentralWrapper>
        </Suspense>
      );
    case "/base-converter":
      return (
        <Suspense>
          <BaseConverter title={"Base Converter"} f={getBinaryString}></BaseConverter>
        </Suspense>
      );
    case "/url-encode-decode":
      return (
        <Suspense>
          <URLCentralWrapper title={"URL Encoder / Decoder"} f={getURLEncoding}></URLCentralWrapper>
        </Suspense>
      );
    case "/svg-to-css":
      return (
        <Suspense>
          <SVG2CSS title={"SVG to CSS Url-Encoder"} f={getSVG2CSS}></SVG2CSS>
        </Suspense>
      );
    case "/sort-deduplicate-lines":
      return (
        <Suspense>
          <SortCentralWrapper title={"Sort Lines"} f={getSortedLines}></SortCentralWrapper>
        </Suspense>
      );
    case "/casing":
      return (
        <Suspense>
          <CasingCentralWrapper title={"Transform Casing"} f={getStringCased}></CasingCentralWrapper>
        </Suspense>
      );
    case "/html-stripper":
      return (
        <Suspense>
          <Extractor title={"HTML stripper"} f={getTextFromHTML}></Extractor>
        </Suspense>
      );
    case "/email-extractor":
      return (
        <Suspense>
          <Extractor title={"Email extractor"} f={getEmail}></Extractor>
        </Suspense>
      );
    case "/url-extractor":
      return (
        <Suspense>
          <Extractor title={"URL extractor"} f={getURLs}></Extractor>
        </Suspense>
      );
    case "/phone-number-extractor":
      return (
        <Suspense>
          <Extractor title={"Mobile number extractor"} f={getPhoneNumber}></Extractor>
        </Suspense>
      );
    case "/delimiters":
      return (
        <Suspense>
          <DelimitersCentralWrapper title={"Add delimiters"} f={getDelimiters}></DelimitersCentralWrapper>
        </Suspense>
      );
    case "/trim-text":
      return (
        <Suspense fallback={<div>Loading...</div>}>
          <TrimCentralWrapper title={"Trim Text"} f={getStringTrimmed}></TrimCentralWrapper>
        </Suspense>
      );
    case "/backslash-escape":
      return (
        <Suspense fallback={<div>Loading...</div>}>
          <Extractor title={"Backslash Escape"} f={getBackslashEscaped}></Extractor>
        </Suspense>
      );
    case "/html-beautify-minify":
      return (
        <Suspense fallback={<div>Loading...</div>}>
          <HTMLBeautifyComponent title={"HTML beautify"} f={getHTMLBeautifyString}></HTMLBeautifyComponent>
        </Suspense>
      );
    case "/js-beautify-minify":
      return (
        <Suspense fallback={<div>Loading...</div>}>
          <JSBeautifyComponent title={"JS beautify"} f={getJSBeautifyString}></JSBeautifyComponent>
        </Suspense>
      );
    case "/css-beautify-minify":
      return (
        <Suspense fallback={<div>Loading...</div>}>
          <CSSBeautifyComponent title={"CSS beautify"} f={geCSSBeautifyString}></CSSBeautifyComponent>
        </Suspense>
      );
    case "/json-beautify-minify":
      return (
        <Suspense fallback={<div>Loading...</div>}>
          <JSBeautifyComponent title={"JSON beautify"} f={getJSBeautifyString}></JSBeautifyComponent>
        </Suspense>
      );
    case "/xml-beautify-minify":
      return (
        <Suspense fallback={<div>Loading...</div>}>
          <XMLBeautifyComponent title={"XML beautify/minify"} f={getXMLBeautifyString}></XMLBeautifyComponent>
        </Suspense>
      );
    case "/yaml-to-json":
      return (
        <Suspense fallback={<div>Loading...</div>}>
          <YAML2JSONComponent title={"YAML to JSON"} f={getYAMLToJSON}></YAML2JSONComponent>
        </Suspense>
      );
    case "/json-to-yaml":
      return (
        <Suspense fallback={<div>Loading...</div>}>
          <JSONTOYAMLComponent title={"JSON to YAML"} f={getJSONToYAML}></JSONTOYAMLComponent>
        </Suspense>
      );
    case "/json-to-csv":
      return (
        <Suspense fallback={<div>Loading...</div>}>
          <JSON2CSV title={"JSON to CSV"} f={getJSONToCSV}></JSON2CSV>
        </Suspense>
      );
    case "/csv-to-json":
      return (
        <Suspense fallback={<div>Loading...</div>}>
          <CSV2JSON title={"CSV to JSON"} f={getCSVToJSON}></CSV2JSON>
        </Suspense>
      );
    case "/xml-validator":
      return (
        <Suspense fallback={<div>Loading...</div>}>
          <ValidatorComponent title={"XML validator"} type={"XML"} f={getXMLValidity}></ValidatorComponent>
        </Suspense>
      );
    case "/yaml-validator":
      return (
        <Suspense fallback={<div>Loading...</div>}>
          <ValidatorComponent title={"YAML validator"} type={"YAML"} f={getYAMLValidity}></ValidatorComponent>
        </Suspense>
      );
    case "/json-validator":
      return (
        <Suspense fallback={<div>Loading...</div>}>
          <ValidatorComponent title={"JSON validator"} type={"JSON"} f={getJSONValidity}></ValidatorComponent>
        </Suspense>
      );
    default:
      return (
        <Suspense fallback={<div>Loading...</div>}>
          <NotFoundComponent></NotFoundComponent>
        </Suspense>
      );
  }
}

export default RouterWrapper;
