import { NavLink } from "react-router-dom";
import styles from "./SideBarItem.module.css";
function SideBarItem(props) {
  return (
    <NavLink className={`${styles.NavLink} ${styles.title}`} to={props.url}>
      <div className={styles.SideBarItemWrapper}>{props && props.title}</div>
      {props.children}
    </NavLink>
  );
}

export default SideBarItem;
