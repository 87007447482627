import styles from "./App.module.css";
import RouterWrapper from "./components/Router/RouterWrapper";
import SideBar from "./components/SideBar/SideBar";
import { useAnalytics } from './useAnalytics';

function App() {
  useAnalytics();  return (
    <div className={styles.App}>
      <SideBar className={styles.SideBar}></SideBar>
      <RouterWrapper></RouterWrapper>
    </div>
  );
}

export default App;
